$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .footer-container {
    background: #222222;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 170px;

    .title {
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      padding: 0px 40px;
    }

    .card {
      width: 670px;
      height: 421px;
      border-radius: 30px;
      background-color: #4e4e4e;
      background-image: url("../../assets/images/symbol.png");
      background-repeat: no-repeat;
      background-size: 105px 91px;
      background-position: 30px 15px;
      box-sizing: border-box;
      padding: 50px 37px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 40px;
      .person {
        display: flex;
        align-items: center;
      }

      .introduce {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        margin-left: 25px;
      }

      .avatar {
        width: 100px;
        height: 100px;
        display: block;
      }
    }

    .card-content {
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
    }

    .action-button {
      margin: 120px 0px 150px;
    }
  }
}