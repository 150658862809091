$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .ai-shopping {
    padding: 200px 40px ;
    color: #1E1E1E;
    font-family: Arial;
    position: relative;

    .ai-title {
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
    }
    .ai-subtitle {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      margin-top: 40px;
    }
    .ai-info {
      margin-top: 120px;
      display: flex;
      flex-direction: column;
      
      .ai-des {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        order: 2;
        color: #333;
        margin-top: 40px;
      }
      .ai-module {
        width: 670px;
        height: 670px;
        order: 1;
      }
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      margin-top: 120px;
    }
    .ai-btn {
      width: 325px;
      height: 88px;
    }
    .ai-btn.btn-blog {
      background-color: #222;
    }
  }
}