$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .data {
      height: 1057px;
      padding-top: 200px;
      
      .data-main {
        flex-direction: column;
      }
  
      .data-value {
        font-size: 80px;
        line-height: 92px;
        width: 100%;
      }
  
      .data-label {
        font-size: 32px;
        line-height: 37px;
        margin-top: 10px;
        width: 100%;
      }
  
      .data-item {
        width: 100%;
        text-align: center;
      }
  
      .data-item+.data-item {
        margin-top: 118px;
      }
  
      .item-1 {
        &::before {
          content: "";
          width: 40px;
          height: 40px;
          border-radius: 20px;
          left: 53px;
          top: 5px;
        }
      }
    }
}