$mobileMaxWidth: 960px;
.select-container {
  font-family: Poppins;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  background-color: transparent;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: column;

  .select-content {
    overflow: hidden;
  }

  &.fill {
    .placeholder {
      color: #000000;
      transform: translateY(0px) scale(1);
    }

  }

  &.focus {
    .placeholder {
      transform: translateY(0px) scale(1);
    }
    .border-line::before {
      transform: scaleX(1);
      transform-origin: left top;
    }
    .arrow-down {
      transform: rotate(180deg);      
    }
  }

  &.error {
    .border-line::before {
      transform: scaleX(1);
      transform-origin: left top;
      background-color: #EB5C2E;
    }
  }

  .placeholder {
    width: 100%;
    font-family: Poppins;
    user-select: none;
    pointer-events: none;
    color: #8D8D8D;
    transition: transform 200ms, color 200ms;
    transform-origin: left bottom;
  }


  .arrow-down {
    flex-shrink: 0;
    align-items: flex-start;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  .select-inner {
    border: none;
    width: 100%;
    font-family: Poppins;
    color: #000;
    user-select: none;
    padding: 0;
    border: none; 
    background: transparent;
    outline: none;
    display: flex;
    justify-content: space-between;
  }

  .border-line {
    width: 100%;
    height: 1PX;
    background-color:#8D8D8D;
    position: relative;
    &::before {
      content: "";
      height: 100%;
      width: 100%;
      transform: scaleX(0);
      transform-origin: right top;
      background-color: #000;
      position: absolute;
      left: 0;
      top: 0;
      transition: transform 0.3s ease-out;
    }
  }

  .select-options {
    width: 100%;
    position: absolute;
    background-color: #fff;
    overflow: hidden;
    left: 0;
    top: 102%;
    z-index: 99999;
    transition: all 0.2s;
    opacity: 0;
    transform: scale(1, 0);
    transform-origin: left top;

    .select-option-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    &.open {
      opacity: 1;
      transform: scale(1, 1);
    }
    .select-option {
      font-family: Inter;
      color: #71747B;
      box-sizing: border-box;
      &.active .select-option-inner{
        background-color: #f7f7f7;
      }
      &:hover .select-option-inner {
        background-color: #f7f7f7;
      }
    }

    .select-option + .select-option {
      border-top: 1PX solid #F8F9FE;
    }
  }
}


@media (min-width: $mobileMaxWidth) {
  .select-container {
    .placeholder {
      font-size: 12px;
      line-height: 20px;
      transform: translateY(24px) scale(1.33333);
    }

    .arrow-down {
      width: 20px;
      height: 20px;
    }

    .border-line {
      margin-top: 10px;
    }

    .select-inner {
      margin-top: 4px;
      font-size: 16px;
      line-height: 20px;
    }

    .select-options {
      box-shadow: 0px 10px 30px 0px #0000001A;
      border-radius: 6px;
      left: 0;
      top: 102%;

      &.open {
        opacity: 1;
        transform: scale(1, 1);
      }

      .select-option {
        height: 48px;
        font-size: 14px;
        padding: 6px;
      }

      .select-option-inner {
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0px 10px;
      }

      .select-option + .select-option {
        border-top: 1PX solid #F8F9FE;
      }
    }
  }
}