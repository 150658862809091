$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .beyond-style-container {
      overflow: hidden;
    .successful {
      background-color: #fff;
      padding-top: 150px;
      box-sizing: border-box;
      padding-left: 40px;
  
      .logo {
        display: block;
        width: 400px;
        height: 82px;
      }
  
      .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        margin-top: 40px;
      }
  
      .subtitle {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        margin-top: 40px;
        margin-right: 40px;
      }
  
      button {
        margin-top: 45px;
      }
  
      .black-friday {
        width: 100%;
        margin-top: 180px;
        height: 530px;
        filter: drop-shadow(15px 20px 20px rgba(0, 0, 0, 0.4));
      }
    }

    .drop-shopping {
      padding-bottom: 250px;
      display: flex;
      flex-direction: column;
      margin-top: 190px;
      .drop-shopping-img {
        width: 100%;
        height: 750px;
        display: block;
      }
  
      >div {
        flex: 1;
        padding: 0px 40px;
      }
  
      .drop-shopping-title {
        margin-top: 40px;
        font-size: 60px;
        font-weight: 700;
        line-height: 69px;
      }
  
      .des {
        margin-top: 40px;
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
      }
    }

    .product-list-wrapper {
      width: 100%;
      overflow: hidden;
      .product-list-transform {
        width: 100%;
        display: flex;
        height: 300px;
      }
      .product-list-row {
        width: 100%;
        .product-list-img {
          width: 300px;
        }
        
        .product-list-img  + .product-list-img {
          margin-left: 20px;
        }
      }
    }

    .product-list-row + .product-list-row {
      margin-top: 20px;
    }

    .product-category {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      padding: 0px 40px;
      margin-top: 60px;
    }

    .top-fashion {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .des {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        order: 1;
        margin-top: 88px;
        padding: 0 40px;

        :last-child {
          margin-top: 40px;
        }
      }

      .top-fashion-img {
        height: 540px;
        margin-top: 250px;
        width: 710px;
        margin-left: 40px;
        filter: drop-shadow(10px 20px 20px rgba(0, 0, 0, 0.4));
      }
    }
    .better-drop-shipping {
      margin-top: 250px;
      display: flex;
      flex-direction: column;
  
      .better-drop-shipping-img {
        height: 540px;
        width: 710px;
        filter: drop-shadow(-15px 20px 20px rgba(0, 0, 0, 0.4));
      }
  
      .des {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        margin-bottom: 200px;
        margin-top: 90px;
        padding: 0px 40px;
      }
    }
  
    .successful-wrapper {
      .successful-main {
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #222222;
        box-sizing: border-box;
        padding: 200px 30px 200px;
  
        :first-child.successful-title {
          font-size: 80px;
          font-weight: 700;
          line-height: 92px;
          margin-bottom: 80px;
        }
  
        .sales {
          width: 100%;
          margin-top: 125px;
          height: 369px;
        }
      }
  
      .successful-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        text-align: center;
      }
    }
  }
} 