$mobileMaxWidth: 960px;
.ai-shopping {
  background-color: #F5F5F5;
}
@media (min-width: $mobileMaxWidth) {
  .ai-shopping {
    padding: 180px 0px 320px;
    color: #1E1E1E;
    font-family: Arial;
    position: relative;

    .ai-title {
      padding: 0px 110px;
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
    }
    .ai-subtitle {
      font-family: Arial;
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      padding: 0px 110px;
      margin-top: 40px;
    }
    .ai-info {
      position: relative;
      height: 900px;
      margin-top: 170px;
      
      .ai-des {
        position: absolute;
        width: 654px;
        height: 564px;
        left: 0;
        z-index: 3;
        background-color: #152E6B;
        color: #fff;
        box-sizing: border-box;
        padding: 60px 30px 60px 110px;
        top: 168px;
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
      }
      .ai-module {
        position: absolute;
        width: 900px;
        height: 900px;
        top: 0;
        right: 0;
      }
    }

    .btn-group {
      height: 60px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 200px;
      display: flex;
      justify-content: center;
    }
    .ai-btn {
      width: 270px;
      height: 60px;
    }

    .ai-btn + .ai-btn {
      margin-left: 20px;
    }

    .ai-btn.btn-blog {
      background-color: #222;
    }
  }
}