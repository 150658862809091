$mobileMaxWidth: 960px;
@media (min-width: $mobileMaxWidth) {
  .global-sales {
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px;
    position: relative;
    height: 548px;

    .global-sales-image {
      height: 548px;
      box-shadow: 18px 117px 190px 0px #0000001A;
      position: absolute;
      width: 800px;
      top: 0px;
      left: -60px;
    }

    .description {
      margin-top: 112px;
      margin-right: 160px;

      button {
        margin-top: 48px;
      }
    }
  }
}
