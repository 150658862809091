$mobileMaxWidth: 960px;
@media (min-width: $mobileMaxWidth) {
  .accessibility {
    display: flex;
    justify-content: space-between;
    padding-bottom: 200px;
    overflow: hidden;

    .description {
      margin-left: 160px;
      margin-top: 136px;
    }

    .accessibility-img {
      height: 535px;
      width: 800px;
      box-shadow: 16px 102px 166px 0px #0000001A;
      transform: translateX(60px);
    }
  }
}