$mobileMaxWidth: 960px;
.gallery {
  background: #F5F5F5;
  position: relative;

  .gallery-title-content > div {
    white-space: nowrap;
  }
  
  .gallery-content {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .col {
    box-sizing: border-box;
    position: absolute;
  }

  .col-transform {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
  }


  
  .image {
    position: absolute;
  }
  
  img {
    display: block;
  }

}


@media (min-width: $mobileMaxWidth) {
  .gallery {
    height: 1361px;
    .gallery-content {
        padding-top: 130px;
    }

    &-title {
      width: 1044px;
      height: 192px;
      font-size: 60px;
      font-weight: 700;
      line-height: 64px;
      position: relative;
      box-sizing: border-box;
      padding: 0px 100px;
      position: relative;
      text-align: center;
    }

    &-title-content {
      position: relative;
      z-index: 2;
    }

    .point {
      position: absolute;
      width: 40px;
      height: 40px;
      background: #EB5C2E;
      border-radius: 50%;
      left: 86px;
      top: 70px;
    }

    .col {
      height: 100%;
      top: 0;
    }

    .col-1 {
      width: 230px;
      left: -130px;
    }

    .by-pl {
      width: 230px;
      top: 192px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .pd-p-h {
      width: 230px;
      top: 636px;
      box-shadow: -15px 18px 84px 0px #00000026;
    }

    .col-2 {
      width: 230px;
      left: 230px;
    }

    .by-hot-list {
      width: 230px;
      top: 493px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .cart {
      width: 230px;
      top: 1119px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .col-3 {
      width: 260px;
      left: 50%;
      transform: translateX(-50%);
    }

    .by-s-pd {
      width: 260px;
      margin-top: 666px;
      border-radius: 40px;
      box-shadow: 0px 35px 54px 0px #00000026, 0px -16px 17px 0px #FFFFFF1A, 0px -6px 24px 0px #0000001A;
    }

    .col-4 {
      width: 230px;
      right: 230px;
    }

    .beauty {
      width: 230px;
      top: 493px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .by-s-h {
      width: 230px;
      top: 914px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .col-5 {
      width: 230px;
      right: -130px;
    }
    
    .show {
      width: 230px;
      top: 192px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .by-h {
      width: 230px;
      top: 548px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }
  }
}