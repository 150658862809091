$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .advantage {
    .advantage-main {
      height: 100%;
      width: 100%;
      background: #090624;
      box-sizing: border-box;
      padding: 140px 40px 200px 40px;
    }

    .m-l {
      margin-left: 20px;
    }

    .advantage-title {
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
      text-align: center;
    }

    .advantage-module {
      border-radius: 30px;
      box-sizing: border-box;

      .title {
        font-size: 36px;
        font-weight: 700;
        line-height: 41px;
      }

      .content {
        font-size: 30px;
        line-height: 34px;
      }
    }

    .advantage-differentiator {
      height: 658px;
      background: #FFF2F2;
      margin-top: 60px;
      padding: 40px 40px 0px;

      .content {
        margin-top: 20px;
      }
    }

    .orders-gmv {
      text-align: center;
      flex-direction: column;
      .orders {
        height: 242px;
        background: #E85021;
      }

      .gmv {
        height: 258px;
        background-color: #fff;
      }

      .col {
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }

      .col-value {
        font-size: 70px;
        font-weight: 700;
        line-height: 80px;
      }

      .col-label {
        font-size: 30px;
        line-height: 34px;
        margin-top: 6px;
      }
    }

    .solutions-communication-team {
      flex-wrap: wrap;

      .col {
        box-sizing: border-box;
        padding: 40px 37px 0px;
      }

      .content {
        margin-top: 20px;
      }

      .solutions {
        width: 266px;
        background: #fff;
        height: 413px;
        order: 0;
        margin-top: 20px;
      }

      .communication {
        background: #532DBF;
        flex: 1;
        order: 2;
        height: 318px;
        margin-top: 20px;
      }

      .team {
        flex: 1;
        background: #24764F;
        order: 1;
        height: 413px;
        margin-top: 20px;
        margin-left: 20px;
      }
    }

    .services {
      height: 243px;

      .service {
        background: #FFF2F2;
        box-sizing: border-box;
        padding: 40px 40px 0px 40px;
        margin-top: 20px;

        .content {
          margin-top: 20px;
        }
      }
    }
  }
}