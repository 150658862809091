$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .description {
    width: 100%;
    box-sizing: border-box;
  }
  
  .description-title {
    margin-bottom: 40px;
  }

  .text-title {
    font-size: 60px;
    font-weight: 700;
    line-height: 69px;
  }

  .text-content {
    font-size: 30px;
    line-height: 40px;
  }

  .description-block {
    display: flex;
    justify-content: space-between;
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
    flex-direction: column;
  }

  .toast-container {
    font-size: 30px;
    padding: 16px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 8px;
    transform: translateY(400px);
    font-weight: normal;
  }
}