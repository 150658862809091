$mobileMaxWidth: 960px;
.input-container {
  font-family: Poppins;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.fill {
    .placeholder {
      color: #000000;
      transform: translateY(0px) scale(1);
    }
  }

  &.focus {
    .placeholder {
      transform: translateY(0px) scale(1);
    }
    .border-line::before {
      transform: scaleX(1);
      transform-origin: top left;
    }
  }

  &.error {
    .border-line::before {
      transform: scaleX(1);
      transform-origin: top left;
      background-color: #EB5C2E;
    }
  }

  .placeholder {
    width: 100%;
    font-family: Poppins;
    user-select: none;
    pointer-events: none;
    color: #8D8D8D;
    transition: transform 200ms ease-out, color 200ms;
    transform-origin: left bottom;
    box-sizing: content-box;
    min-height: 20px;
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    font-family: Poppins;
    color: #000;

  }

  input:focus {
    caret-color: #8D8D8D;
  }

  .border-line {
    width: 100%;
    height: 1PX;
    background-color:#8D8D8D;
    position: relative;
    &::before {
      content: "";
      height: 100%;
      width: 100%;
      transform: scaleX(0);
      transform-origin: right top;
      background-color: #000;
      position: absolute;
      left: 0;
      top: 0;
      transition: transform 300ms ease-out;
    }
  }
}


@media (min-width: $mobileMaxWidth) {
  .input-container {

    .placeholder {
      font-size: 12px;
      line-height: 20px;
      transform: translateY(25px) scale(1.3333);
    }

    input {
      font-size: 16px;
      line-height: 20px;
      height: 20px;
      margin-top: 5px;
    }

    input:focus {
      caret-color: #8D8D8D;
    }

    .border-line {
      margin-top: 10px;
    }
  }
}