$mobileMaxWidth: 960px;
.beyond-style-container {
  background-color: #f5f5f5;
}

@media (min-width: $mobileMaxWidth) {
  .beyond-style-container {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: #222;
      height: 100px;
      width: 100%;
      border-radius: 0px 0px 100px 100px;
    }
    .successful {
      height: 938px;
      background-color: #fff;
      box-sizing: border-box;
      padding-top: 100px;
      padding-left: 160px;
      .logo {
        width: 400px;
        margin-top: 120px;
      }
      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
        margin-top: 40px;
      }
      .subtitle {
        width: 680px;
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
        margin-top: 40px;
      }
      button {
        margin-top: 65px;
      }

      .black-friday {
        width: 600px;
        position: absolute;
        right: 0;
        top: 267px;
        filter: drop-shadow(15px 20px 20px rgba(0, 0, 0, 0.4));
      }
    }

    .drop-shopping {
      display: flex;
      .drop-shopping-img {
        width: 700px;
        height: 700px;
      }
      > div {
        flex: 1;
      }
      .drop-shopping-title {
          margin-top: 76px;
          font-size: 60px;
          font-weight: 700;
          line-height: 69px;
          width: 524px;
          margin-left: 55px;
      }
      .des {
        margin-top: 40px;
        height: 300px;
        background: #F2E7D4;
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        // overflow: hidden;
        position: relative;
        padding-top: 58px;
        box-sizing: border-box;
        > p {
          width: 524px;
          margin-left: 56px;
          
        }
        &::after {
          content: "";
          position: absolute;
          background: #4E3E26;
          width: 50px; 
          height: 175px;
          top: 202px;
          right: 60px;
        }
      }
    }

    .product-category {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      text-align: center;
      margin-top: 124px;
      display: flex;
      justify-content: center;
      > div {
        width: 1002px;
      }
    }

    .product-list-wrapper {
      margin-top: 80px;
      width: 100%;
      overflow: hidden;
      .product-list-transform {
        width: 100%;
        display: flex;
      }
      .product-list-row {
        width: 100%;
        .product-list-img {
          width: 300px;
        }
        
        .product-list-img  + .product-list-img {
          margin-left: 20px;
        }
      }
    }

    .product-list-row + .product-list-row {
      margin-top: 20px;
    }

    .top-fashion {
      display: flex;
      margin-top: 200px;
      justify-content: space-between;
      .des {
        margin-top: 25px;
        width: 584px;
        margin-left: 160px;
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        :last-child {
          margin-top: 40px;
        }
      }
      .top-fashion-img {
        width: 695px;
        height: 529px;
        filter: drop-shadow(10px 20px 20px rgba(0, 0, 0, 0.4));
      }
    }

    .better-drop-shipping {
      margin-top: 210px;
      display: flex;
      .better-drop-shipping-img {
        width: 696px;
        height: 530px;
        filter: drop-shadow(-15px 20px 20px rgba(0, 0, 0, 0.4));
      }
      .des {
        width: 522px;
        font-family: Arial;
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        margin-top: 200px;
        margin-left: 62px;
      }
    }

    .successful-wrapper {
      height: 1207px;
      position: relative;
      margin-top: 340px;
      padding: 0px 0px 100px;
      box-sizing: border-box;
      &::before {
        content: "";
        position: absolute;
        height: 100px;
        width: 100%;
        background-color: #222;
        border-radius: 100px 100px 0px 0px;
        top: -100px;
        left: 0;
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #222;
        height: 100px;
        width: 100%;
        border-radius: 0px 0px 100px 100px;
      }
      .successful-main {
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #222222;
        :first-child.successful-title {
          margin-bottom: 45px;
          margin-top: 60px;
        }

        .sales {
          width: 940px;
          margin-top: 125px;
        }
      }

      .successful-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
      }
    }
  }
}