$mobileMaxWidth: 960px;
.publisher-container {
  background-color: #f5f5f5;
  .block {
    color: #fff;
    background: #222222;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
      text-align: center;
    }
    .subtitle {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      text-align: center;
    }
    .text-line {
      margin-bottom: 80px;
    }
  }
}

@media (min-width: $mobileMaxWidth) {
  .publisher-container {
    .block {
      padding-top: 200px;
      border-radius: 100px 100px 0px 0px;
      .title {
        font-size: 60px;
        font-weight: 700;
        line-height: 69px;
        text-align: center;
      }
      .subtitle {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        text-align: center;
      }
      .text-line {
        margin-bottom: 80px;
      }

    }
    .row {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
    }
    .curate {
      width: 100%;
      overflow: hidden;
      margin-top: 100px;
      padding-right: 160px;
      height: 530px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      .row-text {
        width: 407px;
        margin-top: 130px;
      }
      .curate-img {
        position: absolute;
        width: 940px;
        left: -182px;
      }
    }
    .connections {
      width: 1100px;
      text-align: center;
      margin-top: 190px;
      margin-bottom: 40px;
    }
    .logo-1 {
      width: 1190px;
      top: 40px;
      height: 190px;
    }
    .logo-2 {
      width: 1063px;
      height: 140px;
    }
    .publisher-product-container {
      height: 665px;
      display: flex;
    }
    .publisher-product {
      width: 940px;
    }
    .revenue {
      padding: 0px 200px 0px 170px;
      height: 700px;
      position: relative;
      .row-text {
          width: 536px;
          margin-top: 148px;
      }
      .image {
        position: absolute;
        top: 0px;
        border-radius: 36px;
      }
      .by-s-h {
        width: 220px;
        right: 402px;
        top: 42px;
        box-shadow: 0px 30px 45px 0px #00000026, 0px -5px 21px 0px #0000001A
      }
      .by-s-pd {
        width: 260px;
        right: 170px;
        box-shadow: 0px 35px 54px 0px #00000026, 0px -6px 24px 0px #0000001A;
      }
    }
    .comparison {
      padding-left: 170px;
      position: relative;
      padding-bottom: 140px;
      padding-right: 190px;

      .history-detail {
        width: 260px;
        height: 532px;
        box-shadow: 0px 35px 54px 0px #00000026, 0px -6px 24px 0px #0000001A;
      }

      .history-list {
        width: 294px;
        position: absolute;
        margin-left: 155px;
        top: 250px;
        box-shadow: -75px 80px 118px 0px #00000029;
      }
      .row-text {
        width: 536px;
        margin-top: 150px;
      }
    }
  }
}