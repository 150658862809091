$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .input-container {
    .placeholder {
      font-size: 24px;
      line-height: 40px;
      transform: translateY(48px) scale(1.33333);
    }


    input {
      margin-top: 8px;
      font-size: 32px;
      line-height: 40px;
      height: 40px;
    }

    input:focus {
      caret-color: #8D8D8D;
    }

     .border-line {
      margin-top: 20px;
    }
  }
}