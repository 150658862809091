$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .btn {
    width: 380px;
    height: 88px;
    border-radius: 44px;
    font-size: 32px;
    line-height: 88px;
    text-align: center;

    img {
      width: 32px;
      height: 32px;
      margin-left: 8px;
    }
  }
}
