$mobileMaxWidth: 960px;


@media (min-width: $mobileMaxWidth) {
  .empowerment {
    display: flex;
    height: 677px;
    justify-content: space-between;

    .empowerment-img {
      width: 783.5px;
      height: 626.5px;
    }

    .description {
      margin-left: 160px;
      margin-top: 122px;
    }
  }
}