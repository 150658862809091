$mobileMaxWidth: 960px;
@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/poppins.eot");
  src: url("../assets/fonts/poppins.eot?#iefix")format("embedded-opentype"),
    url("../assets/fonts/poppins.woff")format("woff"),
    url("../assets/fonts/poppins.woff2")format("woff2"),
    url("../assets/fonts/poppins.ttf")format("truetype"),
    url("../assets/fonts/poppins.svg#Poppins")format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}