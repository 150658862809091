$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .merchants-brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .title {
      font-size: 80px;
      font-weight: 700;
      line-height: 92px;
      text-align: center;
    }

    .subtitle {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      text-align: center;
      margin-top: 20px;
    }

    .merchant-title {
      margin-top: 200px;
      margin-bottom: 100px;
    }

    .merchant-list {
      width: 100%;
      transform: translateX(-10px);
    }

    .merchant-row {
      box-sizing: border-box;
      width: 1393px;
      display: flex;
      justify-content: space-between;
    }

    .merchant-row + .merchant-row {
      margin-top: 68px;
    }

    .merchant-image {
      height: 100px;
    }

    .row-1 {
      padding: 0px 17px;
    }

    .row-2 {
      padding: 0px 5px;
    }

    .row-4 {
      padding: 0px 1px;
    }

    .row-5 {
      padding: 0px 4px;
    }

    .brand-title {
      margin: 200px 0px 100px;
    }

    .brand-list {
      width: 100%;
    }

    .brand-row {
      box-sizing: border-box;
      width: 1374px;
      display: flex;
      justify-content: space-between;
    }

    .brand-row + .brand-row {
      margin-top: 68px;
    }

    .brand-image {
      height: 100px;
      flex-shrink: 0;
    }

    .brand-row-1 {
      padding: 0 1px;
    }

    .brand-row-2 {
      padding: 0 25px;
    }

    .brand-row-3 {
      padding: 0 32px;
    }

    .brand-row-4 {
      padding: 0 15px;
    }

  }
}