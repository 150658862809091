$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .gallery {
    height: 1394px;
    .gallery-content {
      padding-top: 215px;
    }

    &-title {
      width: 100%;
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
      text-align: center;
    }

    .col {
      height: 834px;
      top: 560px
    }

    .col-2 {
      width: 115px;
      left: 0px;
    }

    .by-hot-list {
      width: 115px;
      top: 0px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .cart {
      width: 115px;
      top: 625px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .col-3 {
      width: 260px;
      left: 50%;
      transform: translateX(-50%);
    }

    .by-s-pd {
      width: 260px;
      margin-top: 185px;
      border-radius: 40px;
      box-shadow: 0px 35px 54px 0px #00000026, 0px -16px 17px 0px #FFFFFF1A, 0px -6px 24px 0px #0000001A;
    }

    .col-4 {
      width: 115px;
      right: 0px;
    }

    .beauty {
      width: 115px;
      top: 0px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .by-s-h {
      width: 115px;
      top: 423px;
      box-shadow: -18px 21px 97px 0px #00000026;
    }

    .by-s-h-wrapper {
      width: 115px;
      height: 500px;
      overflow: hidden;
    }
    
  }
}
