$mobileMaxWidth: 960px;
.brand-launch {
  background: #F5F5F5;
  font-family: Arial;

  .logo-list {
    align-content: space-between;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > li {
      flex-shrink: 0;
    }
    img {
      display: block;
    }
  }
}

.support-container {
  .support-header {
    color: #1E1E1E;
    text-align: center;
    font-family: Arial;
    font-weight: 700;
  }

  .support-title {
    font-weight: 700;
  }

  .support-wrapper {
    color: #222;
    box-sizing: border-box;
  }

  .border-box {
    box-sizing: border-box;
  }
 
  .support-market {
    background-color: #F7EDB3;
  }

  .support-growth {
    background: #C4A7E7;
  }

  .support-flex .support-brand {
    background: #212222;
    color: #fff;
  }

  .support-powered {
    background-color: #DDDDDD;
  }
  
  .support-curated {
    background-color: #F1AD99;
  }

  .support-flex .support-service {
    background-color: #3F4CDE;
    color: #fff;;
  }

  .support-flex {
    display: flex;
  }

  .support-flex-1 {
    flex-shrink: 0;
  }
}

@media (min-width: $mobileMaxWidth) {
  .brand-launch {
    padding: 238px 140px 300px;
    margin-top: 300px;

    &-header {
      display: flex;
      >img {
        width: 200px;
        height: 200px;
      }
    }
    &-info {
      margin-left: 20px;
      flex: 1;
      padding-top: 52px;
    }
    &-title {
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
      color: #1E1E1E;
    }
    &-description {
      font-size: 32px;
      font-weight: 700;
      line-height: 36.8px;
      color: #333;
    }
    .logo-list {
      margin-top: 100px;
      height: 299px;
      
      .google {
        width: 260px;
      }
      .walmart {
        width: 388px;
      }
      .pinterest {
        width: 286px;
        margin-top: 18px;
      }
      .tiktok {
        width: 278px;
      }
      .shein {
        width: 250px;
        margin-top: 12px;
      }
      .beyondstyle {
        width: 360px;
        margin-top: 4px;
      }
    }

    .introduce {
      color: #333333;
      font-size: 32px;
      font-weight: 700;
      line-height: 37px;
      padding: 200px 0px;
    }
    
    .infomation {
      display: flex;

      .des {
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
        width: 660px;
        box-sizing: border-box;
        padding-right: 90px;

        .btn {
          width: 350px;
          height: 70px;
          margin-top: 50px;
          font-size: 24px;
        }
      }

      .img-robot {
        width: 500px;
        height: 500px;
      }
    }
  }

  .support-flex {
    display: flex;
  }

  .support-wrapper {
    border-radius: 16px;
    padding: 44px 30px;
  }

  .support-container {
    margin-top: 200px;

    .support-direction {
      flex-direction: row
    }
    .support-header {
      font-size: 50px;
      line-height: 58px;
      margin-bottom: 60px;
    }
    .support-title {
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 20px;
    }
    .support-content {
      font-size: 28px;
      line-height: 36px;
    }
    .support-space-left {
      margin-left: 16px;
    }
    .support-space-top {
      margin-top: 16px;
    }
    .support-market {
      height: 250px;
      width: 572px;
    }
    .support-growth, .support-brand {
      height: 492px;
      width: 278px;
      padding: 40px 24px 0px;
    }
    .support-powered, .support-curated  {
      height: 415px;
      width: 278px;
      padding: 44px 24px 0px;
    }
    .support-service {
      height: 327px;
      width: 572px;
      padding: 28px 24px 0px;
    }
  }
}