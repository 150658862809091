$mobileMaxWidth: 960px;
.btn {
  background: #EB5C2E;
  color: #fff;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (min-width: $mobileMaxWidth) {
  .btn {
    width: 350px;
    height: 70px;
    border-radius: 35px;
    font-size: 24px;
    
    img {
      width: 24px;
      height: 24px;
      margin-left: 10px;
    }
  }
}