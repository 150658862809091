$mobileMaxWidth: 960px;
@media (min-width: $mobileMaxWidth) {
  .growth {
    display: flex;
    justify-content: space-between;
    height: 795px;
    overflow: hidden;

    .description {
      margin-right: 160px;
      margin-top: 182px;
    }

    .images-container {
      position: relative;
      // overflow: hidden;
      img {
        position: absolute;
      }

      .growth-1 {
        width: 230px;
        height: 447px;
        top: 68px;
        box-shadow: 8px 51px 83px 0px #0000001A;
        left: -26px;
      }

      .growth-2 {
        width: 270px;
        height: 525px;
        top: 29px;
        left: 189px;
        box-shadow: 9px 60px 97px 0px #0000001A;
      }

      .growth-3 {
        width: 300px;
        height: 583px;
        left: 441px;
        box-shadow: 10px 66px 108px 0px #0000001A;
      }
    }
  }
}