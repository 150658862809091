$mobileMaxWidth: 960px;
.marketplace-container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: 700;
    text-align: center;
  }
}
@media (min-width: $mobileMaxWidth) {
  .marketplace-container {
    padding: 180px 0px 180px;

    .title {
      font-size: 60px;
      line-height: 69px;
    }

    .subtitle {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      margin-top: 80px;
    }

    .imgs-container {
      margin-top: 105px;
      overflow: hidden;
      width: 100%;

      :nth-child(2).row {
        transform: translateX(15%);
      }
    }

    .row {
      width: 100%;
      transform: translateX(-10%);

      .row-group {
        height: 220px;
        display: flex;
        width: 100%;
      }

      img {
        display: block;
        height: 100%;
        flex-shrink: 0;
      }

      img+img {
        margin-left: 20px;
      }
    }

    .row + .row {
      margin-top: 20px;
    }

    .checkout {
      margin-top: 160px;
      width: 939px;
      margin-left: 300px;
      position: relative;
      align-self: flex-start;

      .checkout-page {
        width: 260px;
        height: 535px;
        box-shadow: 0px 35px 54px 0px #00000026, 0px -16px 17px 0px #FFFFFF1A, 0px -6px 24px 0px #0000001A, 0px 16px 16px 0px #00000040;
        border-radius: 38px;
      }

      .checkout-detail {
        width: 240px;
        position: absolute;
        left: 185px;
        top: 240px;
        box-shadow: 0px 64px 94px 0px #00000029;
      }

      > div {
        width: 431px;
        margin-top: 120px;
      }
    }

    .end {
      width: 990px;
      margin: 200px 0px 0px 180px;
      position: relative;
      align-self: flex-start;

      p {
        width: 550px;
        margin-top: 129px;
      }

      .end-2-end {
        width: 260px;
        height: 535px;
        box-shadow: 0px 35px 54px 0px #00000026, 0px -16px 17px 0px #FFFFFF1A, 0px -6px 24px 0px #0000001A, 0px 16px 16px 0px #00000040;
        border-radius: 38px;
        margin-right: 40px;
      }

      .signed {
        position: absolute;
        width: 338px;
        box-shadow: -1px 70px 117px 0px #00000033;
        border-radius: 11px;
        top: 228px;
        right: 0;
      }
    }

    .merchants {
      margin-top: 180px;
      width: 1280px;
      height: 1083px;
      position: relative;
      align-self: flex-start;

      .macy_s {
        width: 624px;
      }

      .kors {
        position: absolute;
        width: 942px;
        top: 553px;
        left: 338px
      }

      .merchants-content {
        width: 558px;
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
        margin-top: 80px;
      }

      div+div {
        margin-top: 35px;
      }
    }
  }
}
