$mobileMaxWidth: 960px;

.successful-case {
  background-color: #f5f5f5;
}

@media (min-width: $mobileMaxWidth) {
  .successful-case {
    .block {
      height: 1440px;
      background: #222222;
      border-radius: 100px;
      box-sizing: border-box;
      padding: 180px 160px 0;
    }

    .row {
      display: flex;
      justify-content: space-between;
      color: #fff;
      align-items: flex-start;

      .logo {
        width: 160px;
      }

      .title-content {
        width: 880px;
        margin-top: 10px;
      }

      .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
      }

      .subtitle {
        margin-top: 30px;
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
      }
    }

    .row +.row {
      margin-top: 100px;
    }

    .merchant {
      :nth-child(2) {
        transform: translateY(100px);
      }

      img {
        width: 260px;
      }
    }

    .tm {
      position: relative;
      margin-top: 128px;
      padding: 40px 160px 200px;
      height: 780px;
      box-sizing: border-box;
      .tm-logo {
        width: 160px;
        height: 160px;
        box-shadow: 0px 24px 104px 0px #0000001A;
      }

      .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        margin-top: 35px;
      }

      .subtitle {
        width: 600px;
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
        margin-top: 35px;
      }

      .image {
        position: absolute;
        top: 0px;
        right: 160px;
        border-radius: 38px;
        box-shadow: 0px 17px 18px 0px #00000040, 0px 37px 56px 0px #00000026, 0px -17px 18px 0px #FFFFFF1A, 0px -6px 25px 0px #0000001A;
      }

      .store {
        width: 271px;
      }

      .product {
        width: 246px;
        top: 25px;
        right: 360px;
      }
    }
  }
}