$mobileMaxWidth: 960px;
html, body, ul, li, h1, h2, h3, h4, h5, p, input {
  margin: 0px;
  padding: 0px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;

  &:visited, &:active {
    color: inherit;
  }
}

button, li, a, div {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: Arial;
  color: #1E1E1E;
}

input[type="checkbox"] {
  appearance: none;
}

button {
  outline: none;
}

img {
  display: block;
}