$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .tabs-title-group {
    height: 80px;
    border-radius: 40px;
    padding: 10px;
    justify-content: space-between;
    .tabs-title-item {
      font-size: 26px;
      line-height: 60px;
      height: 60px;
      z-index: 1;
      padding: 0px 17px;
    }

    .tabs-title-mark {
      height: 60px;
      border-radius: 30px;
    }
  }
}
