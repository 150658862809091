$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .api-container {
    padding: 100px 40px 140px 40px;

    .api-list {
      width: 100%;
    }

    .api-item {
      width: 100%;
      height: 900px;
      border-radius: 30px;
      box-shadow: 0px 65px 257px 0px #00000014;
      box-sizing: border-box;
      padding: 0px 40px;

      .api-item-img {
        width: 382px;
        height: 382px;
        display: block;
        margin-top: 40px;
      }
    }

    .text-title {
      margin-top: 40px;
      font-size: 56px;
      line-height: 64px;
    }

    .text-content {
      font-size: 36px;
      line-height: 41px;
      text-align: center;
      margin-top: 15px;
    }

    .api-item + .api-item {
      margin-top: 60px;
    }

    button {
      margin-top: 100px;
    }
  }
}