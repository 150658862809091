$mobileMaxWidth: 960px;
@media (min-width: $mobileMaxWidth) {
  .api-integration {
    display: flex;
    justify-content: space-between;
    height: 660px;
    overflow: hidden;

    .image-wrapper {
      position: relative;

      .best-value {
        height: 427px;
        left: -60px;
        top: 0;
        box-shadow: -15px 17px 80px 0px #00000026;
      }

      .shoe-price {
        width: 593px;
        top: 150px;
        left: 147px;
        box-shadow: -13px 16px 73px 0px #00000026;
      }

      .img {
        position: absolute;
      }
    }

    .description {
      margin-right: 160px;
      margin-top: 141px;
    }
  }
}