$mobileMaxWidth: 960px;

@media (min-width: $mobileMaxWidth) {
  .fashion {
    display: flex;
    justify-content: space-between;
    margin-bottom: 180px;

    .discover {
      width: 650px;
      height: 676px;
      margin-right: 106px;
    }

    .description {
      margin-left: 160px;
      margin-top: 224px;
    }
  }
}