$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .accessibility {
    padding-bottom: 138px;
    box-sizing: border-box;
    padding: 0px 40px;

    .accessibility-img {
      margin-top: 40px;
      width: 100%;
      height: 535px;
      box-shadow: 13px 86px 139px 0px #0000001A;
    }
  }
}