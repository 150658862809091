$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .successful-case {
    .block {
      background: #222222;
      box-sizing: border-box;
      padding: 160px 0px;
      color: #fff;
    }
    .row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: #fff;
      align-items: center;
      text-align: center;
      .logo {
        width: 160px;
      }

      .title-content {
        margin-top: 40px;
      }

      .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        margin-top: 60px;
      }

      .subtitle {
        margin-top: 30px;
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        padding: 0px 40px;
      }
    }

    .merchant {
      flex-direction: row;
      position: relative;
      width: 100%;
      overflow: hidden;
      height: 703px;
      margin-top: 150px;
      :first-child {
        left: -130px;
        bottom: 0;
        
      }
      :nth-child(2) {
        left: 50%;
        transform: translate(-50%, 0);
        top: 0;
      }

      :last-child {
        right: -130px;
        bottom: 0;
      }

      img {
        width: 260px;
        height: 563px;
        position: absolute;
      }
    }

    .tm {
      position: relative;
      padding: 160px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .tm-logo {
        width: 160px;
        height: 160px;
        box-shadow: 0px 24px 104px 0px #0000001A;
      }

      .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        margin-top: 60px;
      }

      .subtitle {
        margin-top: 30px;
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        text-align: center;
        padding: 0 40px;
      }

      .image {
        border-radius: 38px;
        box-shadow: 0px 17px 18px 0px #00000040, 0px 37px 56px 0px #00000026, 0px -17px 18px 0px #FFFFFF1A, 0px -6px 25px 0px #0000001A;
      }

      .store {
        width: 310px;
        height: 658px;
        transform: translateX(94px);
        position: relative;
        z-index: 2;
        margin-top: 100px;
      }

      .product {
        position: absolute;
        width: 246px;
        bottom: 220px;
        left: 120px;
      }
    }
  }
}