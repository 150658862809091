$mobileMaxWidth: 960px;
.company-page {
  background-color: #222;
  color: #fff;
  overflow: hidden;

  img {
    display: block;
  }

}

@media (min-width: $mobileMaxWidth) {
  .company-page {
    
    padding: 0 160px 290px;

    .company-module {
      display: flex;
      justify-content: space-between;
      
      &.boxder-x {
        margin-top: 106px;
      }
      &.logistics-platform {
        margin-top: 200px;
      }
    }
    .company-module-text {
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      &.border-x {
        width: 461px;
        margin-top: 98px;
      }
      &.logistics-platform {
        width: 380px;
        margin-top: 35px;
      }
      &.company-culture {
        margin-top: 45px;
      }
    }
    .border-x-img {
      width: 599px;
      height: 400px;
    }

    .logistics-platform-img {
      width: 680px;
      height: 401px;
    }

    .company-culture-img {
      height: 470px;
      margin-top: 200px;
    }

    .news-investors-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 50px;
      margin-top: 150px;
    }

    .img-wrapper {
      margin-top: 10px;
      display: flex;
      .news-img {
        width: 663px;
        height: 442px;
      }

      .investors {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 0 20px 20px 0px;
      }

      .investors-1 {
        width: 340px;
      }

      .investors-2 {
        margin-top: 10px;
        width: 307px;
      }


    }

    .investors-link {
      display: block;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      text-decoration: underline;
      padding-top: 20px;
    }
  }
}
