$mobileMaxWidth: 960px;
.advantage {
  background-color: #f5f5f5;
  
  .flex {
    display: flex;
  }
  .flex-1 {
    flex: 1;
  }

  .c-w {
    color: #fff;
  }
  .t-a-c {
    text-align: center;
  }
}


@media (min-width: $mobileMaxWidth) {
  .advantage {
    height: 1785px;
    background-color: #f5f5f5;

    .advantage-main {
      height: 100%;
      width: 100%;
      background: #090624;
      border-radius: 100px;
      box-sizing: border-box;
      padding: 140px 160px 0px 160px;
    }

    .m-l {
      margin-left: 48px;
    }

    .advantage-title {
      font-size: 50px;
      font-weight: 700;
      line-height: 57px;
    }

    .advantage-module {
      border-radius: 16px;
      box-sizing: border-box;

      .title {
        font-size: 30px;
        font-weight: 700;
        line-height: 34px;
      }

      .content {
        font-size: 26px;
        line-height: 30px;
      }
    }

    .advantage-differentiator {
      height: 345px;
      background: #FFF2F2;
      margin-top: 60px;
      padding: 40px 36px 0px;

      .content {
        margin-top: 21px;
      }
    }

    .row {
      margin-top: 20px;

      .col+.col {
        margin-left: 20px;
      }
    }

    .orders-gmv {
      text-align: center;

      .orders {
        width: 450px;
        height: 206px;
        background: #E85021;
      }

      .gmv {
        background-color: #fff;
      }

      .col-value {
        font-size: 70px;
        font-weight: 700;
        line-height: 80px;
        margin-top: 44px;
      }

      .col-label {
        font-size: 26px;
        line-height: 30px;
      }
    }

    .solutions-communication-team {
      height: 320px;

      .col {
        box-sizing: border-box;
        padding: 32px 34px 0px;
      }

      .content {
        margin-top: 20px;
      }

      .solutions {
        width: 266px;
        background: #fff;
      }

      .communication {
        background: #532DBF;
      }

      .team {
        width: 345px;
        background: #24764F;
      }
    }

    .services {
      height: 364px;

      .service {
        background: #FFF2F2;
        box-sizing: border-box;
        padding: 44px 0px 0px 33px;

        .title {
          width: 293px;
        }

        .content {
          margin-top: 20px;
        }
      }

      .country {
        display: flex;
        flex-direction: column;

        .country-item {
          flex: 1;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 50px;
          font-weight: 700;
          line-height: 57px;
        }

        .country-item+.country-item {
          margin-top: 20px;
        }

        .usa {
          background: #E85021;
        }

        .china {
          background: #24764F;
        }

        .korean {
          background-color: #fff;
        }

        .australia {
          background: #532DBF;
        }

        .japan {
          background: #E85021;
        }
      }

      .country-1 {
        width: 220px;
      }

      .country-2 {
        width: 298px;
      }
    }
  }
}