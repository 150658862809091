$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .fashion {
    margin-top: 200px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .discover {
      margin-top: 40px;
      height: 676px;
    }
  }
}