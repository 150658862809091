$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .contact-us-page {
    padding: 50px 38px 230px;

    .form-item.brands-merchansts {
      margin-top: 94px;
    }
    .line-height-28 {
      line-height: 28px;
    }
    .module-title {
      margin-bottom: 60px;
    }
    .form-item {
      margin-top: 50px;
      &.cloud-stores, &.explore {
        margin-top: 94px;
      }
    }
    .contact-us-title {
      width: 371px;
      font-size: 64px;
      font-weight: 700;
      line-height: 74px;
    }

    .contact-us-subtitle {
      font-size: 28px;
      font-weight: 400;
      line-height: 32px;
      margin-top: 20px;
    }

    .module-form {
      width: 676px;
    }

    .products {
      width: 100%;
      margin-top: 36px;
    }

    .action-button {
      width: 100%;
      height: 88px;
      margin-top: 100px;
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
}