$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .marketplace-container {
    padding: 200px 0px 200px;

    .title {
      font-size: 60px;
      line-height: 69px;
    }

    .subtitle {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      margin-top: 60px;
      text-align: center;
    }

    .imgs-container {
      margin-top: 100px;
      overflow: hidden;
      width: 100%;

      :nth-child(2).row {
        transform: translateX(15%);
      }
    }

    .row {
      width: 100%;
      transform: translateX(-10%);

      .row-group {
        height: 220px;
        display: flex;
        width: 100%;
      }

      img {
        display: block;
        height: 100%;
        flex-shrink: 0;
      }

      img + img {
        margin-left: 20px;
      }
    }

    .row + .row {
      margin-top: 20px;
    }

    .checkout {
      margin-top: 240px;
      width: 100%;
      position: relative;

      .checkout-page {
        width: 320px;
        height: 659px;
        box-shadow: 0px 19px 19px 0px #00000040, 0px 43px 66px 0px #00000026, 0px -20px 21px 0px #FFFFFF1A, 0px -7px 30px 0px #0000001A;
        border-radius: 48px;
        margin-left: 150px;
      }

      .checkout-detail {
        width: 282px;
        position: absolute;
        left: 380px;
        top: 295px;
        box-shadow: 0px 75px 111px 0px #00000029;
      }

      > div {
        margin-top: 100px;
        text-align: center;
      }
    }

    .end {
      margin: 180px 0px 200px 0px;
      width: 100%;
      position: relative;
      align-self: flex-start;
      align-items: center;
      p {
        margin-top: 100px;
        order: 1;
        width: 670px;
        text-align: center;
      }

      .end-2-end {
        width: 320px;
        height: 658px;
        box-shadow: 0px 35px 54px 0px #00000026, 0px -16px 17px 0px #FFFFFF1A, 0px -6px 24px 0px #0000001A, 0px 16px 16px 0px #00000040;
        border-radius: 38px;
        order: 0;
      }

      .signed {
        position: absolute;
        width: 498px;
        border-radius: 11px;
        top: 276px;
        left: 50%;
        box-shadow: -2px 102px 173px 0px #00000033;
        transform: translateX(-50%);
      }
    }

    .merchants {

      .macy_s {
        width: 100%;
      }

      .kors {
        width: 100%;
        margin-top: 200px;
      }

      .merchants-content {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        text-align: center;
        margin-top: 40px;
        padding: 0 40px;
      }
      
    }
  }
}