$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .brand-launch {
    box-sizing: border-box;
    padding: 200px 40px;
    margin-top: 200px;

    &-header {
      position: relative;
     
      >img {
        position: absolute;
        width: 120px;
        height: 120px; 
        left: 0;
        top: 0;
      }
    }
    &-info {
      overflow: hidden;
    }
    &-title {
      font-size: 72px;
      font-weight: 700;
      line-height: 83px;
      margin: 19px 0px 0px 130px;
    }
    &-description {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      margin-top: 30px;
    }
    .logo-list {
      margin-top: 100px;
      height: 495px;
      
      .google {
        width: 240px;
      }
      .walmart {
        width: 359px;
      }
      .pinterest {
        width: 286px;
        margin-top: 6px;
      }
      .tiktok {
        width: 278px;
        margin-right: 10px;
      }
      .shein {
        width: 240px;
        margin-top: 9px;
      }
      .beyondstyle {
        width: 360px;
      }
    }

    .introduce {
      color: #333333;
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      padding: 120px 0px;
    }

    .infomation {
      display: flex;
      flex-direction: column;

      .des {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        order: 2;
        color:#333;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 380px;
          height: 88px;
          font-size: 32px;
          margin-top: 120px;
        }
      }
      .img-robot {
        order: 1;
        width: 670px;
        height: 670px;
      }
    }
  }

  .support-container {
    margin-top: 201px;

    .support-wrapper {
      border-radius: 30px;
      padding: 40px 40px 0px;
    }

    .support-direction {
      flex-direction: column;
    }
    .support-header {
      font-size: 50px;
      line-height: 57px;
      margin-bottom: 60px;
    }
    .support-title {
      font-size: 36px;
      line-height: 41px;
      margin-bottom: 20px;
    }
    .support-content {
      font-size: 30px;
      line-height: 35px;
    }
    .support-space-left {
      margin-left: 20px;
    }
    .support-space-top {
      margin-top: 20px;
    }
    .support-market {
      height: 209px;
      br {
        display: none;
      }
    }
    .support-growth {
      height: 529px;
      width: 316px;
    }
    .support-brand  {
      height: 529px;
      width: 334px;
    }
    .support-powered {
      height: 434px;
      width: 316px;
    }
    .support-curated  {
      height: 434px;
      width: 334px;
    }
    .support-service {
      height: 345px;
    }
    .support-mobile {
     margin-left: 0px; 
     margin-top: 20px;
    }
  }
}