$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .growth {
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    .description {
      margin-right: 160px;
      margin-top: 182px;
      order: 1;
    }

    .images-container {
      margin-top: 40px;
      position: relative;
      width: 100%;
      height: 510px;
      order: 2;

      img {
        position: absolute;
      }

      .growth-1 {
        width: 201px;
        top: 60px;
        box-shadow: 7px 45px 73px 0px #0000001A;
        left: 0px;
      }

      .growth-2 {
        width: 236px;
        height: 458px;
        top: 21px;
        left: 188px;
        box-shadow: 8px 52px 85px 0px #0000001A;
      }

      .growth-3 {
        width:  262px;
        height: 509px;
        left: 408px;
        box-shadow: 9px 58px 95px 0px #0000001A;
      }
    }
  }
}