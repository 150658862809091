$mobileMaxWidth: 960px;


@media (min-width: $mobileMaxWidth) {

  .description-block {
    display: flex;
    justify-content: space-between;
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
    align-items: flex-start;
  }

  .description {
    width: 460px;
  }

  .description-title {
    margin-bottom: 20px;
  }

  .text-title {
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
  }

  .text-content {
    font-size: 20px;
    line-height: 28px;
  }

  .toast-container {
    font-size: 24px;
    font-weight: normal;
    padding: 8px 12px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 6px;
    transform: translateY(300px);
  }
}