$mobileMaxWidth: 960px;
.page-header {
  width: 100%;
  &.dark {
    color: #fff;
    background-color: #222;
  }

  &.hoary {
    background-color: #f5f5f5;
  }

  .header-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 160px;
    box-sizing: border-box;
    &.fixed {
      position: fixed;
      left: 0px;
      top: 0px;
      background-color: #fff;
      z-index: 1000;
      transform: translate3d(0, -100%, 0);
      color: #1E1E1E;
    }
    &.show.fixed {
      transition: transform 0.25s ease-out;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .title {
    font-weight: 700;
    flex-shrink: 0;
  }

  .link-group {
    display: flex;
  }

  .link-item {
    font-weight: 700;
    display: flex;
    align-items: center;
    cursor: pointer;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .contact-us {
      background: #EB5C2E;
      color: #fff;
    }
  }
 
}

@media (min-width: $mobileMaxWidth) {
  .page-header {
    height: 68px;
    position: relative;
    z-index: 4000;
    .header-content {
      height: 68px;
      padding: 0px 160px;
    }

    .title {
      font-size: 32px;
      line-height: 37px;
    }

    .link-item .anchor-group {
      position: absolute;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      color: #000;
      top: 100%;
      z-index: 33333;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 10px 30px 0px #0000001A;
      opacity: 0.4;
      transform: translate3d(0px, 0px, 0px);
      transition: height 200ms ease-in-out, opacity 200ms ease-in-out; 
      transform-origin: left top;
      height: 0px;
      > a {
        display: block;
        white-space: nowrap;
        height: 48px;
        padding: 6px 10px;
        box-sizing: border-box;
        flex-shrink: 0;
        &:hover .link-inner {
          background-color: #f7f7f7;
        }
        .link-inner {
          padding: 0px 40px 0px 10px;
          box-sizing: border-box;
          height: 100%;
          border-radius: 6px;
          display: flex;
          align-items: center;
          color: #333;
          font-family: Arial;
          font-size: 16px;
          font-weight: normal;
        }
      }
      a + a {
        border-top: 1PX solid #ebedf4;
      }
    }

    .link-item.anchor .anchor-group.show {
      height: 192px;
      opacity: 1;
    }

    .link-item.brands .anchor-group.show {
      height: 48px;
      opacity: 1;
    }

    .link-item {
      font-size: 18px;
      line-height: 21px;
      &.anchor {
        position: relative;
      }
      a {
        height: 68px;
      }

      .contact-us {
        width: 130px;
        height: 40px;
        border-radius: 20px;
        line-height: 40px;
      }
    }

    .link-item + .link-item {
      margin-left: 40px;
    }

    .link-item-text {
      display: block;
      height: 33px;
      line-height: 33px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: #FF6A49;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      &.active:after,
      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}
