$mobileMaxWidth: 960px;
.api-container {
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .api-item {
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    transition: transform 0.5s;
  }
}

@media (min-width: $mobileMaxWidth) {
  .api-container {
    padding-top: 160px;
    padding-bottom: 85px;

    .api-list {
      display: flex;
      justify-content: center;
    }
    
    .api-item {
      width: 350px;
      height: 500px;
      border-radius: 30px;
      box-shadow: 0px 34px 134px 0px #00000014;
  
      .api-item-img {
        width: 200px;
        display: block;
        margin-top: 40px;
      }
  
      &:hover {
        transform: translate3d(0px, -32px, 0px);
      }
    }
  
    .text-title {
      margin-top: 28px;
    }
  
    .text-content {
      line-height: 23px;
      width: 284px;
      margin-top: 18px;
      text-align: center;
    }
  
    .api-item+.api-item {
      margin-left: 35px;
    }
  
    button {
      margin-top: 60px;
    }
  }
}