$mobileMaxWidth: 960px;
.blog-page {
  font-family: Arial;
  .blog-item {
    color: #222222;
    display: flex;
    .blog-subtitle, .blog-subtitle {
      width: 100%;
    }
    a {
      text-decoration: underline;
    }
  }
  
  .ellipsis-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: $mobileMaxWidth) {
  .blog-page {
    .blog-item {
      font-size: 16px;
      line-height: 18px;
      display: flex;
      .blog-item-image {
        width: 840px;
        height: 630px;
        flex-shrink: 0;
      }
      .blog-content {
        flex: 1;
        padding: 60px 50px 0 80px;
      }

      .br-mobile {
        display: none;
      }

      .blog-title {
        font-size: 48px;
        font-weight: 700;
        line-height: 55px;
      }

      .blog-subtitle {
        margin-top: 10px;
        color: #666666
      }
      .blog-action {
        display: block;
        width: 220px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: none;
        outline: none;
        border-radius: 30px;
        background-color: #222;
        font-weight: 700;
        font-size: 24px;
        color: #fff;
        padding: 0;
        margin-top: 40px;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .article-page {
    padding: 90px 80px 144px;
    display: flex;
    font-size: 18px;
    line-height: 25px;
    min-height: calc(100vh - 68px);
    box-sizing: border-box;
    .article-info {
      width: 462px;
      flex-shrink: 0;
      h1 {
        font-size: 64px;
        font-weight: 700;
        line-height: 70px;
        margin-bottom: 20px;
      }
      span {
        display: block;
      }
      img {
        width: 360px;
        height: 270px;
        display: block;
        margin-top: 20px;
      } 
    }
    .article-content {
      flex: 1;
      margin-left: 40px;
      p + p, p + ul, ul + p {
        margin-top: 20px;
      }
      a {
        text-decoration: underline;
      }
      li + li {
        margin-top: 12px;
      }
      li {
        padding-left: 2em;
        position: relative;
        &::before {
          display: block;
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          background-color: #000;
          border-radius: 3px;
          left: 1em;
          top: 9px;
        }
      }
    }
  }
}