$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .checkbox-container {

    .checkbox-label {
      padding-bottom: 6px;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
    }

    .checkbox-text {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
      margin-left: 20px;
    }

    .checkbox-item {
      width: 100%;
      margin-top: 50px;
    }

    .checkbox-item > label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-bottom: 10px;
    }
   
    .checkbox-status {
      width: 32px;
      height: 32px;
      background-size: 32px 32px;
    }
  }
}