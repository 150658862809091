$mobileMaxWidth: 960px;
@media (min-width: $mobileMaxWidth) {
  .merchants-brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .title {
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
    }

    .subtitle {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      margin-top: 20px;
    }

    .merchant-title {
      margin-top: 205px;
      margin-bottom: 95px;
    }

    .merchant-list {
      width: 100%;
    }

    .merchant-row {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .merchant-row+.merchant-row {
      margin-top: 68px;
    }

    .merchant-image {
      height: 100px;
    }

    .row-1 {
      padding: 0px 41px;
    }

    .row-2 {
      padding: 0px 29px;
    }

    .row-3 {
      padding: 0px 24px;
    }

    .row-4 {
      padding: 0px 25px;
    }

    .row-5 {
      padding: 0px 28px;
    }

    .brand-title {
      margin: 245px 0px 95px;
    }

    .brand-list {
      width: 100%;
    }

    .brand-row {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .brand-row+.brand-row {
      margin-top: 68px;
    }

    .brand-image {
      height: 100px;
    }

    .brand-row-1 {
      padding: 0 34px;
    }

    .brand-row-2 {
      padding: 0 58px;
    }

    .brand-row-3 {
      padding: 0 65px;
    }

    .brand-row-4 {
      padding: 0 48px;
    }

    .brand-row-5 {
      padding: 0 33px;
    }
  }
}