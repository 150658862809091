$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .empowerment {
    margin-top: 200px;
      .description {
        padding: 0px 40px;
      }
      .empowerment-img {
        width: 100%;
        margin-top: 40px;
        display: block;
      }
    }
}