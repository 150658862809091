$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .publisher-container {
    .block {
      padding-top: 200px;
      padding-bottom: 230px;
      overflow: hidden;
      .title {
        font-size: 60px;
        font-weight: 700;
        line-height: 69px;
        text-align: center;
        padding: 0px 40px;
      }

      .subtitle {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        text-align: center;
        padding: 0px 40px;
        margin-top: 30px;
      }

      .row {
        font-size: 40px;
        font-weight: 700;
        line-height: 46px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        flex-direction: column;
      }

      .curate {
        margin-top: 200px;

        .row-text {
           width: 407px;
           margin-top: 75px;
           width: 100%;
           text-align: center;
           box-sizing: border-box;
           padding:  0 40px;
        }
        .curate-img {
          width: 690px;
          height: 390px;
        }
      }

      .logo-1 {
        width: 1190px;
        height: 140px;
        margin-top: 250px;
      }
  
      .logo-2 {
        width: 1063px;
        height: 140px;
        margin-top: 40px;
      }

      .publisher-product-container {
        height: 450px;
        display: flex;
      }

      .publisher-product-inner {
        height: 100%;
      }

      .publisher-product {
        width: 670px;
      }
      .connections {
        margin: 80px 0px 240px;
      }

      .revenue {
        position: relative;
        .row-text {
          order: 1;
          margin-top: 100px;
          padding: 0px 40px;
          text-align: center;
        }
        .image {
          top: 0px;
          border-radius: 36px;
        }
        .by-s-h {
          position: absolute;
          width: 214px;
          top: 42px;
          left: 156px;
          box-shadow: 0px 30px 45px 0px #00000026, 0px -5px 21px 0px #0000001A
        }
        .by-s-pd {
          position: relative;
          z-index: 2;
          width: 260px;
          height: 535px;
          box-shadow: 0px 35px 54px 0px #00000026, 0px -6px 24px 0px #0000001A;
          transform: translateX(89px);
        }
      }

      .comparison {
        position: relative;
        margin-top: 250px;
        width: 100%;
        

        .history-detail {
          width: 300px;
          height: 618px;
          transform: translateX(-84px);
          box-shadow: 0px 35px 54px 0px #00000026, 0px -6px 24px 0px #0000001A;
        }

        .history-list {
          width: 294px;
          height: 329px;
          position: absolute;
          top: 340px;
          left: 360px;
          box-shadow: -75px 80px 118px 0px #00000029;
        }
        .row-text {
          margin-top: 155px;
          padding: 0 40px;
          text-align: center;
        }
      }
    }
  } 
}