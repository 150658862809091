$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .global-sales {
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    margin-top: 200px;

    .global-sales-image-wrapper {
      order: 2;
    }
    .global-sales-image {
      margin-top: 40px;
      height: 459px;
      width: 100%;
      box-shadow: 15px 78px 60px 0px #0000001A;
    }

    .description {
      order: 1;
    }
    .btn {
      order: 3;
      align-self: center;
      margin: 100px 0px;
    }
  }
}