$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .company-page {
    padding: 100px 40px 440px;
    align-items: center;

    img {
      width: 100%;
    }

    .company-module {
      display: flex;
      flex-direction: column;

      &.boxder-x {
        .company-module-text-wrapper {
          order: 1
        }
      }

      &.logistics-platform {
        margin-top: 160px;
      }
    }

    .company-module-text {
      font-size: 32px;
      font-weight: 700;
      line-height: 37px;

      &.border-x {
        margin-top: 30px;
        order: 1;
      }

      &.logistics-platform {
        margin-top: 40px;
      }

      &.company-culture {
        margin-top: 40px;
      }
    }

    .border-x-img {
      height: 447px;
      order: 0;
    }

    .logistics-platform-img {
      height: 395px;
    }

    .company-culture-img {
      width: 100%;
      margin-top: 160px;
    }

    .news-investors-title {
      margin-top: 160px;
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
    }

    .img-wrapper {
      margin-top: 20px;
      display: flex;

      .news-img {
        width: 397px;
        height: 264px;
      }

      .investors {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 0 20px 20px 0px;
      }

      .investors-1 {
        width: 203px;
      }

      .investors-2 {
        margin-top: 10px;
        width: 183px;
      }
    }

    .investors-link {
      display: block;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      text-decoration: underline;
      padding-top: 20px;
    }
  }
}