$mobileMaxWidth: 960px;
.checkbox-container {
  width: 100%;
  &.error {
    .checkbox-label {
      color: #EB5C2E
    }
  }
  .checkbox-label  {
    color: #000;
    transition: color 0.25s;
  }
  .checkbox-input {
    display: none;
  }
  .checkbox-status {
    display: block;
    background-image: url("../../assets/images/checkbox.png");
    background-position: top left;
    background-repeat: no-repeat;

    &.checked {
      background-image: url("../../assets/images/checkbox-on.png");
    }
  }
}

@media (min-width: $mobileMaxWidth) {
.checkbox-container {
    &.error {
      .checkbox-label {
        color: #EB5C2E
      }
    }
    .checkbox-label {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      padding-bottom: 10px;
    }

    .checkbox-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
    }

    .checkbox-group {
      display: flex;
      flex-wrap: wrap;
    }

    .checkbox-item {
      width: 50%;
    }

    .checkbox-item > label {
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-top: 10px;
    }

    .checkbox-status {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
    }
  }
}