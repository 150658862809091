$mobileMaxWidth: 960px;
.text-line {
  position: relative;
  display: inline;

  &.link {
    cursor: pointer;
  }

  .line {
    position: absolute;
    width: 100%;
    left: 0px;
    background: #EB5C2E;
    transition: transform 500ms;
    transform-origin: left top;
    transform: scale(0);
    
    &.line-active {
      transform: scale(1);
    }
  }
}


@media (min-width: $mobileMaxWidth) {
  .line {
    height: 4px;
    bottom: -4px;
  }
}