$mobileMaxWidth: 960px;
@media (min-width: $mobileMaxWidth) {
  .footer-container {
    background: #222222;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 115px;

    .title {
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      top: 115px;
      text-align: center;
    }

    .card {
      width: 540px;
      height: 340px;
      border-radius: 30px;
      background-color: #4e4e4e;
      background-image: url("../../assets/images/symbol.png");
      background-repeat: no-repeat;
      background-size: 90px 78px;
      background-position: 22px 12px;
      box-sizing: border-box;
      padding: 40px 30px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .person {
        display: flex;
        align-items: center;
      }

      .introduce {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-left: 20px;
      }

      .avatar {
        width: 80px;
        height: 80px;
        display: block;
      }
    }

    .card + .card {
      margin-left: 40px;
    }

    .card-list {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }

    .card-content {
      font-size: 22px;
      font-weight: 700;
      line-height: 36px;
    }

    .action-button {
      margin: 50px 0px 115px;
    }
  }
}