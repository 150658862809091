$mobileMaxWidth: 960px;
.action-button {
  transition: background-color 200ms;
  > span {
    display: flex;
    align-items: center;
  }
  &.touch {
    background-color: #000;
  }
      
  &.loading {
    img {
      animation: loading 1s linear forwards infinite;
    }
  }
}

@media (min-width: $mobileMaxWidth) {
  .contact-us-page {
    display: flex;
    justify-content: space-between;
    padding: 50px 160px 158px;

    .brands-merchants-title {
      width: 156px;
      text-align: center;
    }
    
    .marketplaces-publishers-title {
      width: 205px;
      text-align: center;
    }

    .marketplacepublisher {
      .explore {
        .checkbox-group {
          height: 90px;
        }
      }
    }

    .contact-us-title {
      width: 347px;
      font-size: 60px;
      font-weight: 700;
      line-height: 69px;
    }

    .contact-us-subtitle {
      font-size: 18px;
      line-height: 21px;
      color: #333333;
      width: 227px;
      margin-top: 50px;
    }

    .contact-us-content {
      width: 676px;
    }
    
    .tabs-container .tabs-title-group{
      margin-bottom: 50px;
    }

    .module-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .input-container {
      width: 318px;
      margin-bottom: 30px;
    }
    .business-type {
      width: 318px;
    }
    .company-role {
      margin-bottom: 0px;
    }

    .checkbox-container {
      margin-top: 20px;
    }

    .revenue {
      margin-top: 30px;
      width: 510px;
    }

    .products {
      width: 100%;
      margin-top: 36px;
    }

    .action-button {
      width: 100%;
      height: 60px;
      margin-top: 50px;
      img {
        width: 30px;
        height: 30px;
      }
    }

    .module-form .cloud-stores {
      margin-top: 50px;
      .checkbox-group {
        height: 90px;
      }
    }

    .explore,.cloud-stores {
      .checkbox-group {
        flex-direction: column;
        flex-wrap: wrap;
        height: 108px;
        align-content: flex-start;
      }
      .option-item-0 {
        width: 263px;
      }
      .option-item-1 {
        width: 260px;
      }
      .option-item-2 {
        width: auto;
      }
    }

    .explore {
      margin-top: 50px;
    }

    .brands-merchansts {
      margin-top: 50px;
      .checkbox-group {
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        height: 151px;
    
        .option-item-0 {
          width: 263px;
        }
    
        .option-item-1 {
          width: auto;
        }
      }
    }

    .deliver-products, .sell-products {
      width: 100%;
      margin: 27px 0px 0px;
    }

  }
}


@keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}