$mobileMaxWidth: 960px;
.fade-transtion-content.fadeOutUp {
  opacity: 0;
  transform: translate3d(0, 60px, 0);;
}

.fade-transtion-content.fadeInUp {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fade-transtion-content {
  width: 100%;
  height: 100%;
  opacity: 0.1;
  transform: translate3d(0, 60px, 0);
  transition-property: transform opacity;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 60px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 60px, 0);
  }
}
