$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .page-header {
    height: 100px;


    &.dark .open-icon .icon-line {
      background-color: #fff;
    }
  
    .header-content.fixed .open-icon .icon-line {
      background-color: #222;
    }

    .header-content {
      height: 100px;
      padding: 0px 32px;
    }

    .title {
      font-size: 36px;
      line-height: 41px;
    }

    .link-item {
      font-size: 28px;
      line-height: 32px;

      a {
        height: 100px;
      }

      .contact-us {
        width: 228px;
        height: 72px;
        border-radius: 36px;
        line-height: 72px;
      }
    }

    .open-icon {
      width: 48px;
      height: 48px;
      position: relative;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-left: 20px;
      .icon-line {
        display: block;
        width: 34px;
        height: 2PX;
        background-color: #222;
        position: absolute;
      }
      .line-1 {
        transform: translate3d(0px, -10px, 0px);
      }
      .line-2 {
        transform: translate3d(0px, 0px, 0px) scale(1, 1);
      }
      .line-3 {
        transform: translate3d(0px, 10px, 0px);
      }
      &.close {
        .line-1 {
          transform: translate3d(0px, 0px, 0px) rotate(45deg);
        }
        .line-2 {
          transform: translate3d(0px, 0px, 0px) scale(0, 0)
        }
        .line-3 {
          transform: translate3d(0px, 0px, 0px) rotate(-45deg);
        } 
      }
    }
  }
}

.open-icon {
  width: 48px;
  height: 48px;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 20px;

  .icon-line {
    display: block;
    width: 34px;
    height: 2PX;
    background-color: #222;
    position: absolute;
  }

  .line-1 {
    transform: translate3d(0px, -10px, 0px);
  }

  .line-2 {
    transform: translate3d(0px, 0px, 0px);
  }

  .line-3 {
    transform: translate3d(0px, 10px, 0px);
  }
}

.aside-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  transform: translate3d(100%, 0px, 0px);

  &.open {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

.aside-content {
  width: 670px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2001;
  transform: translate3d(100%, 0px, 0px);
  box-sizing: border-box;
  padding: 134px 0px 0px 0px;
  transition: transform 0.25s ease-out;
  top: 0;
  .open-icon {
    position: absolute;
    top: 45px;
    right: 32px;
    .line-1 {
      transform: translate3d(0px, 0px, 0px) rotate(45deg);
    }

    .line-3 {
      transform: translate3d(0px, 0px, 0px) rotate(-45deg);
    }
  }
  &.open {
    transform: translate3d(0px, 0px, 0px);
  }
  .link-item {
    border-bottom: 1px solid #EEEEEE;
    > a {
      display: block;
      display: block;
      padding: 0px 32px;
      font-family: Arial;
      font-size: 36px;
      font-weight: 700;
      line-height: 41px;
      height: 91px;
      box-sizing: border-box;
    }
  }
  .link-item + .link-item {
    margin-top: 20px;
  }

  .link-item-action {
    padding: 0px 32px;
    margin-top: 60px;
    .btn {
      width: 100%;
    }
  }
}