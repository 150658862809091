$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .blog-page {
    .blog-item {
      display: flex;
      flex-direction: column;
      .blog-item-image {
        width: 100%;
        height: 562.5px;
        flex-shrink: 0;
      }
      .blog-content {
        order: 2;
        flex: 1;
        padding: 40px 40px 160px;
      }

      .br-mobile {
        display: none;
      }

      .blog-title {
        font-size: 48px;
        font-weight: 700;
        line-height: 55px;
      }

      .blog-subtitle {
        margin-top: 30px;
        color: #666666;
        font-size: 30px;
        line-height: 34.5px;
      }
      .blog-action {
        display: block;
        width: 330px;
        height: 88px;
        text-align: center;
        line-height: 88px;
        border: none;
        outline: none;
        border-radius: 40px;
        background-color: #222;
        font-weight: 700;
        font-size: 32px;
        color: #fff;
        padding: 0;
        margin-top: 30px;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .article-page {
      position: relative;
      padding: 80px 40px 771px;
      display: flex;
      flex-direction: column;
      .article-info {
        h1 {
          font-size: 64px;
          font-weight: 700;
          line-height: 74px;
          margin-bottom: 8px;

        }
        span {
          font-size: 26px;
          line-height: 30px;
          display: block;
        }
        img {
          width: 750px;
          height: 562.5px;
          position: absolute;
          left: 0;
          bottom: 160.5px;
        } 
      }
      .article-content {
        font-size: 30px;
        line-height: 42px;
        flex: 1;
        margin-top: 48px;
        p + p, p + ul, ul + p {
          margin-top: 32px;
        }
        a {
          text-decoration: underline;
        }
        li + li {
          margin-top: 18px;
        }
        li {
          padding-left: 2em;
          position: relative;
          &::before {
            display: block;
            position: absolute;
            content: "";
            width: 6px;
            height: 6px;
            background-color: #000;
            border-radius: 3px;
            left: 1em;
            top: 17px;
          }
        }
      }
    }
  }
}