$mobileMaxWidth: 960px;
.tabs-title-group {
  display: flex;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(180deg, #F7F7F7 0%, #F0F0F0 100%);
  .tabs-title-item {
    font-family: Arial;
    color: #333333;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    transition: color 200ms;
    &.active {
      font-weight: 700;
      color: #fff;
    }
  }
  .tabs-title-mark {
    background: #EB5C2E;
    position: absolute;
    left: 0;
    transition: transform 200ms, width 200ms;
  }
}

@media (min-width: $mobileMaxWidth) {
  .tabs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .tabs-title-group {
    padding: 10px 10px;
    border-radius: 30px;
    height: 60px;
   
    .tabs-title-item {
      font-size: 16px;
      line-height: 40px;
      padding: 0px 10px;
      position: relative;
      z-index: 1;
    }
    .tabs-title-item  + .tabs-title-item {
      margin-left: 15px;
    }
    .tabs-title-mark {
      top: 10px;
      height: 40px;
      border-radius: 20px;
    }
  }
}