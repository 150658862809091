$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .select-container {

    .placeholder {
      left: 0;
      font-size: 24px;
      line-height: 40px;
      transition: transform 200ms, color 200ms;
      box-sizing: border-box;
      transform: translateY(48px) scale(1.3333);
    }

    .arrow-down {
      width: 40px;
      height: 40px;
    }

    .select-inner {
      margin-top: 8px;
      width: 100%;
      font-size: 32px;
      line-height: 40px;
    }

    .border-line {
      margin-top: 24px;
    }

    .select-options {
      box-shadow: 0px 10px 30px 0px #0000001A;
      border-radius: 12px;
      left: 0;
      top: 102%;

      &.open {
        opacity: 1;
        transform: scale(1, 1);
      }

      .select-option {
        height: 96px;
        line-height: 96px;
        font-size: 28px;
        padding-left: 36px;
        padding: 8px;
        .select-option-inner {
          border-radius: 8px;
          padding: 0px 20px;
          box-sizing: border-box;
        } 
      }
    }
  }
}