$mobileMaxWidth: 960px;
@media (max-width: $mobileMaxWidth) {
  .api-integration {
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    margin-top: 80px;

    .image-wrapper {
      margin-top: 80px;
      position: relative;
      order: 2;
      height: 427px;
      width: 100%;

      .best-value {
        width: 546px;
        height: 357px;
        left: 0;
        top: 0;
        box-shadow: -12px 15px 67px 0px #00000026;
      }

      .shoe-price {
        width: 496px;
        top: 125px;
        right: 0px;
        box-shadow: -11px 13px 61px 0px #00000026;
      }

      .img {
        position: absolute;
      }
    }

    .description {
      order: 1
    }
  }
}