$mobileMaxWidth: 960px;
.data {
  box-sizing: border-box;
  background-color: #fff;
  

  .data-main {
    display: flex;
    justify-content: center;
  }

  .data-value {
    font-weight: 700;
    text-align: center;
  }

  .data-label {
    text-align: center;
  }

  .data-item {
    display: flex;
    flex-direction: column;
  }

  .item-1 {
    position: relative;
    align-self: flex-end;
    > span {
        position: relative;
        z-index: 2;
      }
    &::before {
      content: "";
      position: absolute;
      background: #FF6A49;
    }
  }
}

@media (min-width: $mobileMaxWidth) {
    .data {
      height: 677px;
      padding-top: 252px;
  
      .data-value {
        font-size: 60px;
        line-height: 69px;
      }
  
      .data-label {
        line-height: 23px;
        font-size: 20px;
        margin-top: 10px;
      }
  
      .data-item {
        display: flex;
        flex-direction: column;
      }
  
      .data-item+.data-item {
        margin-left: 100px;
      }
  
      .data-0 {
        width: 169px;
      }
  
      .data-2 {
        width: 303px;
      }
  
      .data-1 {
        width: 453px;
      }
  
      .item-1 {
        &::before {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          left: -18px;
          top: 5px;
        }
      }
    }
}